.select {
    border-radius: calcOutValue(8);
    border: 1px solid var(--color-ui-blue-10);
    overflow: hidden;

    &__top {
        padding: calcOutValue(14) calcOutValue(52) calcOutValue(14) calcOutValue(18);
        font-size: calcOutValue(14);
        color: var(--color-ui-dark-Blue);
        position: relative;
        cursor: pointer;
        white-space: nowrap;
        display: flex;
        align-items: center;
        line-height: 1;

        span {
            line-height: inherit;
        }

        i {
            position: absolute;
            right: calcOutValue(18);
            top: 50%;
            margin-top: calcOutValue(-12);
            transition: 0.4s;
        }
    }

    &__wr-list {
        height: 0;
        overflow: hidden;
        transition: 0.4s;
    }

    &__list {
        &-item {
            button {
                background: transparent;
                padding: calcOutValue(9) calcOutValue(18);
                font-size: calcOutValue(14);
                color: var(--color-ui-dark-Blue);
                width: 100%;
                text-align: left;
                transition: 0.4s;

                &:hover,
                &.active {
                    background: var(--color-ui-blue-10);
                }
            }
        }
    }

    &.open {
        .select__top i {
            transform: rotateX(-180deg);
        }
    }
}