.accordion-item {
    display: flex;
    gap: calcOutValue(12);
    padding: calcOutValue(12) calcOutValue(20);

    &__left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: calcOutValue(25);
    }

    &__right {
        display: flex;
        align-items: center;
        margin-left: 1rem;
    }

    &__btn-down {
        overflow: hidden;
        svg {
            stroke: var(--color-ui-accent-color);
        }

        &.loade {
            svg {
                animation: identifierDown 2s linear infinite;
            }
        }
    }

    &__box {
        display: flex;
        justify-content: space-between;
        flex: 1 1 auto;
    }

    &__centr {
        cursor: pointer;
        flex: 1 1 auto;
    }

    &__btn-play {
        line-height: 0;
    }

    &__btn-arrow {
        cursor: pointer;
        line-height: 0;
        transition: 0.4s;
    }

    &_main {
        border-radius: calcOutValue(12);
        background: var(--color-ui-blue-main);
    }

    &.borde {
        border-top: calcOutValue(1) solid var(--color-ui-blue-main);
        border-left: calcOutValue(1) solid var(--color-ui-blue-main);
        border-right: calcOutValue(1) solid var(--color-ui-blue-main);

        &:last-child {
            border-bottom: calcOutValue(1) solid var(--color-ui-blue-main);
        }
    }

    &.active {
        background: rgba(242, 247, 251, 0.95);
    }
}

@keyframes identifierDown {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(calcOutValue(24));
    }
}

.section-accordion {
    border-radius: calcOutValue(12);

    &:not(:first-child) {
        margin-top: calcOutValue(20);
    }

    &__wr-list {
        transition: 0.4s;
        height: 0;
        overflow: hidden;
    }

    &__list {
        border: calcOutValue(1) solid var(--color-ui-blue-main);

        .accordion-item {
            &:not(:first-child) {
                border-top: calcOutValue(1) solid var(--color-ui-blue-main);
            }

            .cen-accor {
                cursor: auto;
            }
        }
    }

    &.open {
        .accordion-item_main {
            border-radius: calcOutValue(12) calcOutValue(12) 0 0;

            .accordion-item__btn-arrow {
                transform: rotateX(-180deg);
            }
        }
    }
}

.cen-accor {
    max-width: 96%;

    &__h {
        font-size: calcOutValue(16);
        line-height: 158%;
        color: var(--color-ui-dark-Blue);
        max-width: calcOutValue(610);

        i {
            font-style: normal;
            padding-right: calcOutValue(10);
        }

        span {
            color: #BDBDBD;
            font-size: calcOutValue(14);
        }
    }

    &__des {
        font-size: calcOutValue(14);
        line-height: 171%;
        letter-spacing: calcOutValue(-0.28);
        width: 100%;
        max-width: calcOutValue(610);

        @media(min-width: 767px) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &__time {
        line-height: 160%;
        font-size: calcOutValue(12);
        margin-top: calcOutValue(7);
        max-width: calcOutValue(610);
    }
}

@media(max-width: 767px) {
    .cen-accor {
        max-width: 100%;

        &__des {
            font-size: calcOutValue(12);

            p {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                max-width: 100%;
            }
        }
    }

    .cen-accor__h {
        padding-right: calcOutValue(30);
    }

    .accordion-item {
        position: relative;
        padding-left: calcOutValue(16);
        padding-right: calcOutValue(16);

        &__btn-arrow {
            position: absolute;
            right: calcOutValue(16);
            top: calcOutValue(12);
        }
    }
}