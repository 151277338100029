.faqs {
    margin-top: calcOutValue(64);

    &__title {
        text-transform: uppercase;
        font-size: calcOutValue(24);
        letter-spacing: calcOutValue(-0.48);
        margin-bottom: calcOutValue(30);
        font-weight: 400;
        color: var(--color-ui-accent-color);
    }

    &__wr {
        border-radius: calcOutValue(12);
        border: calcOutValue(1) solid var(--color-ui-blue-10);
        background: linear-gradient(313deg, #E7F1F8 -8.9%, #E7F1F8 -8.89%, #EBF3F9 46.46%, #F7FAFD 100%);
        box-shadow: 4px 4px 7px 0px rgba(64, 117, 151, 0.12);
        padding: calcOutValue(40) calcOutValue(20);
        display: flex;
        flex-direction: column;
        gap: calcOutValue(18);
    }

    &-subtitle {
        font-size: calcOutValue(20);
        font-style: normal;
        font-weight: 500;
        color: var(--color-ui-dark-Blue);
    }
}

.item-fag {
    padding: calcOutValue(20);
    border-radius: 8px;
    border: calcOutValue(1) solid var(--color-ui-blue-10);

    &__top {
        display: flex;
        align-items: center;
        font-size: calcOutValue(20);
        font-style: normal;
        font-weight: 500;
        color: var(--color-ui-dark-Blue);
        justify-content: space-between;

        span {
            font-size: calcOutValue(20) !important;
            font-style: normal !important;
            font-weight: 500 !important;
            color: var(--color-ui-dark-Blue) !important;
        }

        svg {
            width: calcOutValue(24);
            height: calcOutValue(24);
            margin-left: calcOutValue(16);
            transition: 0.4s;
        }

        cursor: pointer;
    }

    &__show {
        height: 0;
        overflow: hidden;
        transition: 0.4s;
    }

    &__des {
        padding-top: calcOutValue(12);
        font-size: calcOutValue(14);
        letter-spacing: calcOutValue(0.28);

        span {
            font-size: calcOutValue(14) !important;
            color: var(--color-ui-accent-color) !important;
        }
    }

    &.open {
        .item-fag__top {
            svg {
                transform: rotateX(-180deg);
            }
        }
    }
}

@media(max-width: 767px) {
    .item-fag {
        &__top {
            font-size: calcOutValue(16);
        }
    }
}