.binfo {
    padding-bottom: calcOutValue(28);

    &-h {
        background: url(../../img/bg_top.png) center/cover no-repeat;
        padding: calcOutValue(48) 0 calcOutValue(20) 0;
        backdrop-filter: blur(12px);
        border-radius: calcOutValue(12);
        display: flex;
        justify-content: flex-end;

        &_w {
            border-radius: calcOutValue(12);
            background: rgba(255, 255, 255, 0.5);
            padding: calcOutValue(17) calcOutValue(42);
            margin-left: auto;
        }

        &__title {
            font-size: calcOutValue(28);
            color: var(--color-ui-accent-color);
            margin-top: calcOutValue(6);
            font-family: 'inter';
            font-weight: 400;
            letter-spacing: 0;
            text-transform: uppercase;
        }

        &_im {
            width: calcOutValue(302);
        }

        .books_container_content {
            display: flex;
            width: 100%;
            gap: calcOutValue(25);
        }
    }

    &_wid {
        width: calcOutValue(741);
    }

    &-m {
        &_row {
            padding-top: calcOutValue(25);
            display: flex;
            gap: calcOutValue(25);
        }

        &__left {
            width: calcOutValue(302);
            margin-top: calcOutValue(-147);

            &-btn {
                display: flex;
                gap: calcOutValue(14);
                margin-top: calcOutValue(15);
                ;
            }
        }

        &__img {
            width: 100%;
            height: calcOutValue(418);
            position: relative;
            box-shadow: -6px 4px 4px 0px rgba(106, 85, 85, 0.20);

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &::before {
                content: '';
                position: absolute;
                z-index: 2;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: url(../../img/bg_img_audio_book.png) center/cover no-repeat;
            }
        }

        &__bnt-favor {
            position: absolute;
            top: calcOutValue(10);
            right: calcOutValue(10);
            width: calcOutValue(32);
            height: calcOutValue(32);
            z-index: 10;

            svg {
                width: inherit;
                height: inherit;
            }
        }

        &_cont {
            display: flex;
            flex-direction: column;
            gap: calcOutValue(20);
        }

        &__title {
            font-size: calcOutValue(16);
            color: var(--color-ui-accent-color);
            text-transform: uppercase;
            letter-spacing: calcOutValue(-0.32);
            line-height: 1;
        }

        &__des {
            letter-spacing: calcOutValue(-0.28);
            font-size: calcOutValue(14);
            line-height: 171.429%;

            button {
                color: var(--color-w-blue);
                font-size: inherit;
            }
        }

        &__items {
            display: flex;
            flex-wrap: wrap;
            column-gap: calcOutValue(37);
            row-gap: calcOutValue(20);
        }
    }
}

.m-item {
    display: flex;
    align-items: center;
    gap: calcOutValue(8);
    font-size: calcOutValue(16);
    line-height: 120%;
}

.btn-book-dow {
    display: flex;
    align-items: center;
    white-space: nowrap;
    gap: calcOutValue(10);
    font-size: calcOutValue(16);
}

.btn-book-dow_two {
    svg {
        stroke: #537486;
        transition: 0.4s;
    }

    &:hover {
        svg {
            stroke: var(--color-ui-Blue-button-disable);
        }
    }
}

@media(max-width: 767px) {
    .binfo-h {
        background: transparent;
        padding: 0;

        &_im {
            display: none;
        }

        &_w {
            padding: 0;
            background: transparent;
        }

        &__title {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            color: var(--color-ui-dark-Blue);
            margin-top: calcOutValue(24);

            &_first {
                font-weight: 500;
                text-transform: uppercase;
            }

            &_tr {
                display: none;
            }

            &_last {
                font-size: calcOutValue(14);
                text-transform: none;
            }
        }
    }

    .books_container_content {
        padding: 0;
    }

    .binfo-m {
        margin-top: calcOutValue(18);

        &__left {
            margin-top: 0;
        }

        &_row {
            flex-direction: column;
            gap: calcOutValue(42);
        }

        &__left {
            width: 100%;
            padding-left: calcOutValue(20);
            padding-right: calcOutValue(20);

            &-btn {
                gap: calcOutValue(20);

                .btn {
                    padding-left: calcOutValue(12);
                    padding-right: calcOutValue(12);
                }
            }
        }

        &_cont {
            width: 100%;
            gap: calcOutValue(24);
        }

        &__bnt-favor {
            top: calcOutValue(11);
            right: calcOutValue(18);
        }

        &__title {
            color: var(--color-ui-dark-Blue);
            font-weight: 500;
        }

        &__des {
            font-size: calcOutValue(12);
            color: var(--color-ui-dark-Blue);
        }

        &__items {
            gap: calcOutValue(16);
        }
    }

    .m-item {
        font-size: calcOutValue(14);
        color: var(--color-ui-dark-Blue);

        svg {
            width: calcOutValue(20);
            height: calcOutValue(20);
        }
    }
}