*,
*::after,
*::before {
    box-sizing: border-box;
    -webkit-appearance: none;
}

img {
    max-width: 100%;
}

html {
    font-size: defFronVw(12, 1728);
}

p,
h1,
h2,
h3,
h4,
h5 {
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: 'inter';
    letter-spacing: 0;
}

button {
    outline: none;
    cursor: pointer;
    line-height: 1.4285;
}

.font_w_500 {
    font-weight: 500;
}

.font_w_300 {
    font-weight: 300;
}

.text_center {
    text-align: center;
}

.text_decor_none {
    text-decoration: none;
}

ul,
ol {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

button {
    background: transparent;
    outline: none;
    border: none;
    padding: 0;
    font-family: 'inter', sans-serif;
}

.icon-sprite {
    width: calcOutValue(24);
    height: calcOutValue(24);
}

body {
    padding: 0;
    margin: 0;
    font-family: 'inter', sans-serif;
    font-weight: 400;
    font-style: normal;
    background: var(--color-ui-linear-main);
    line-height: 1.4285;
    color: var(--color-ui-accent-color);
    overflow-x: hidden;
}

.wrapper {
    min-height: 100vh;
    padding-top: calcOutValue(98);

    &.no_scrol {
        height: 100vh;
        overflow: hidden;
    }
}

.n_poin {
    pointer-events: none;
}

.crumbs {
    ul {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        line-height: 150%;
        letter-spacing: calcOutValue(0.24);
        font-size: calcOutValue(16);

        li {
            padding-right: calcOutValue(16);
            position: relative;

            &:not(:last-child) {
                &::after {
                    content: '/';
                    position: absolute;
                    font-size: calcOutValue(16);
                    font-weight: 500;
                    line-height: 150%;
                    letter-spacing: calcOutValue(0.24);
                    color: var(--color-ui-blue-30);
                    right: calcOutValue(5);
                }
            }
        }

        a {
            color: var(--color-ui-accent-color);
            font-weight: 500;
            text-decoration: none;
        }

        span {
            font-weight: 300;
            color: var(--color-ui-blue-30);
            text-transform: uppercase;
        }
    }
}

@media(max-width: 767px) {
    html {
        font-size: defFronVw(12, 390);
    }

    a,
    button {
        cursor: none !important;
    }

    .crumbs {
        ul {
            text-transform: uppercase;

            a {
                color: var(--color-ui-dark-Blue);
            }
        }
    }
}