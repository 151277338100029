.auth {
    width: 100vw;
    min-height: 100vh;
    background: url(../../img/auth_bg.png) center/cover no-repeat, lightgray -136px -216px / 115.741% 148.111% no-repeat;
    padding-bottom: calcOutValue(40);

    &__header {
        padding: calcOutValue(32) calcOutValue(84);
    }

    &__logo {
        img {
            width: calcOutValue(142);
            height: calcOutValue(25);
        }
    }

    &__main {
        margin-top: calcOutValue(22);
        padding-right: calcOutValue(220);
        display: flex;
        justify-content: flex-end;
    }
}

.aform_gap {
    display: flex;
    flex-direction: column;

    &_12 {
        gap: calcOutValue(12);
    }

    &_18 {
        gap: calcOutValue(18);
    }
}

.aform {
    border-radius: calcOutValue(20);
    border: calcOutValue(0.5) solid var(--ui-blue-blue-30, var(--color-ui-blue-30));
    background: var(--color-whit-20);
    box-shadow: -7px 7px 17px 0px rgba(147, 170, 194, 0.20);
    min-width: calcOutValue(743);
    min-height: calcOutValue(640);
    display: flex;
    justify-content: center;
    padding: calcOutValue(20);

    &__registr {
        max-width: calcOutValue(408);
        margin-top: calcOutValue(87);
    }

    &__profile {
        margin-top: calcOutValue(72);
        max-width: calcOutValue(360);
        min-width: calcOutValue(360);
    }

    &__m-360 {
        max-width: calcOutValue(360);
        margin: calcOutValue(30) auto 0;
    }

    &-h1 {
        letter-spacing: calcOutValue(1.2);
        font-size: calcOutValue(80);
        color: var(--color-ui-dark-Blue);
        line-height: 1.14;
    }

    &-p {
        font-size: calcOutValue(16);
        letter-spacing: calcOutValue(-0.28);

        &_registr {
            margin-top: calcOutValue(8);
        }

        &_error {
            color: var(--color-w-red) !important;

            span {
                color: var(--color-w-blue) !important;
            }
        }
    }

    &__text_b {
        letter-spacing: calcOutValue(0.32);
        font-size: calcOutValue(16);
        color: var(--color-ui-dark-Blue);
        text-align: center;

        &.non {
            pointer-events: none;
            opacity: 0.5;
        }
    }
}

.fprof {
    &__avat {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: calcOutValue(12);
    }

    &__d {
        text-align: center;
        letter-spacing: calcOutValue(-0.28);
        font-size: calcOutValue(14);
    }

    &__fields {
        margin-top: calcOutValue(30);
    }

    &__gap {
        display: flex;
        flex-direction: column;

        &_12 {
            gap: calcOutValue(12);
        }
    }

    &__btn {
        display: flex;
        gap: calcOutValue(16);
        margin-top: calcOutValue(30);

        &>div {
            flex: 1 1 auto;
            width: calc(50% - 0.66667rem);
        }
    }
}

@media(max-width: 767px) {
    .auth {
        padding-bottom: calcOutValue(10);

        &__header {
            padding: calcOutValue(55) calcOutValue(20) calcOutValue(20);
        }

        &__main {
            margin-top: calcOutValue(65);
            padding-right: calcOutValue(20);
            padding-left: calcOutValue(20);
        }
    }

    .aform {
        min-width: 100%;
        min-height: calcOutValue(314);
        width: 100%;
        padding: calcOutValue(28) calcOutValue(20) calcOutValue(26) calcOutValue(20);

        .form {
            width: 100%;
        }

        &__registr {
            margin-top: 0;
        }

        &__profile {
            margin-top: calcOutValue(12);
            max-width: 100%;
            min-width: 100%;
        }

        &__m-360 {
            margin-top: calcOutValue(12);
        }

        &-h1 {
            font-size: calcOutValue(36);
        }

        &-p {
            font-size: calcOutValue(12);
            letter-spacing: normal;
            color: var(--color-ui-dark-Blue);

            &_registr {
                margin-top: calcOutValue(18);
            }
        }

        &__text_b {
            font-size: calcOutValue(14);
        }

        &_registr {
            gap: calcOutValue(8);
        }

        &__wr_btn {
            margin: calcOutValue(8) auto 0 auto;
            max-width: calcOutValue(240);
            width: 100%;
        }

        &_login {
            gap: calcOutValue(16);
            margin-top: calcOutValue(18);

            &_wr_btn {
                margin-top: calcOutValue(2);
            }
        }

        &_code {
            margin-top: calcOutValue(8);
            gap: calcOutValue(12);

            &_wr_btn {
                margin-top: calcOutValue(0);
            }

            .aform_gap_12 {
                gap: calcOutValue(8);
            }

            .aform__text_b {
                padding-bottom: calcOutValue(4);
            }
        }
    }


    .fprof {
        &__d {
            color: var(--color-ui-dark-Blue);
        }

        &__btn {
            &>div {
                flex: 1 1 auto;
                width: calc(50% - 0.66667rem);
            }
        }
    }
}