.aycard {
    padding: calcOutValue(16) calcOutValue(23);
    border-radius: calcOutValue(12);
    background: var(--color-white);
    box-shadow: 8px 8px 12px 0px rgba(48, 96, 123, 0.07);
    display: flex;
    gap: calcOutValue(18);

    &__audio {
        display: none;
    }

    &__panel {
        min-width: calcOutValue(178);
        width: calcOutValue(178);
        height: calcOutValue(245);
        box-shadow: -7px 4px 4px 0px rgba(106, 85, 85, 0.20);
        position: relative;
        line-height: 0;
        overflow: hidden;

        &_link-img {
            position: relative;
            display: flex;
            height: inherit;
            background: #C1CAD1;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 6;
                background: url(../../img/bg_img_audio_card.png) center/cover no-repeat;
            }
        }

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    &__inf {
        flex: 1 1 auto;
        padding-top: calcOutValue(40);
        position: relative;

        &-grid {
            display: grid;
            margin-top: calcOutValue(36);
            grid-template-columns: calcOutValue(88) calcOutValue(64);
            grid-template-rows: auto;
            row-gap: calcOutValue(12);
            column-gap: calcOutValue(16);
        }

        &_opas {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            width: 100%;
            height: 100%;
        }
    }

    &__title {
        color: var(--color-ui-dark-Blue);
        font-size: calcOutValue(20);
        text-decoration: none;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    &__des {
        font-size: calcOutValue(10);
        margin-top: calcOutValue(4);
    }
}

.card-panel {
    position: absolute;
    display: flex;
    align-items: center;
    left: 0;
    bottom: calcOutValue(-1);
    width: 100%;
    justify-content: center;
    gap: calcOutValue(10);
    z-index: 10;
    backdrop-filter: blur(12px);
    background: rgba(97, 142, 184, 0.04);

    &_button {
        width: calcOutValue(20);
        height: calcOutValue(20);
        border-radius: calcOutValue(4);

        svg {
            width: inherit;
            height: inherit;
        }
    }

    &_play {
        width: calcOutValue(68);
        height: calcOutValue(68);

        svg {
            width: inherit;
            height: inherit;
        }
    }
}

.card-item-info {
    display: flex;
    gap: calcOutValue(6);
    font-size: calcOutValue(10);
    align-items: center;

    span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    svg {
        min-width: calcOutValue(18);
        height: calcOutValue(18);
    }
}

.player-card {
    display: none;
}

@media(max-width: 767px) {
    .aycard {
        padding: calcOutValue(24) calcOutValue(16);

        &__panel {
            min-width: calcOutValue(119);
            width: calcOutValue(119);
            height: calcOutValue(174);
        }

        &__inf {
            padding-top: 0;
        }

        &__des {
            font-size: calcOutValue(12);
            font-weight: 400;
            color: var(--color-ui-dark-Blue);
            margin-top: 0;
        }
    }

    .card-panel {
        gap: calcOutValue(10);

        &_button {
            width: calcOutValue(20);
            height: calcOutValue(20);
        }

        &_play {
            width: calcOutValue(44);
            height: calcOutValue(44);
        }
    }
}