@function defFronVw($makerFont, $desingWidth) {
    @return ($makerFont/($desingWidth/100))*1vw;
}

@function calcOutValue($inpx, $desingFontSize: 12) {
    @return ($inpx/$desingFontSize)*1rem;
}

@function calcOutValue($inpx, $desingFontSize: 12) {
    @return ($inpx/$desingFontSize)*1rem;
}
