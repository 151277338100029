.cardrecent {
    box-shadow: 8px 8px 12px 0px rgba(48, 96, 123, 0.07);
    padding: calcOutValue(30);
    border-radius: calcOutValue(12);
    background: #fff;
    display: flex;
    gap: calcOutValue(20);

    &:not(:first-child) {
        margin-top: calcOutValue(20);
    }

    .aycard__panel {
        min-width: calcOutValue(180);
    }

    &__main {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: calcOutValue(20);
        max-width: calcOutValue(880);
        padding-bottom: calcOutValue(10);
        flex: 1 1 auto;
    }

    &__title {
        a {
            text-decoration: none;
            font-size: calcOutValue(18);
            color: var(--color-ui-accent-color);

            span {
                font-weight: 500;
                font-size: calcOutValue(26);
                color: var(--color-ui-dark-Blue) !important;
            }
        }
    }

    &__des {
        margin-top: calcOutValue(6);
        color: var(--color-ui-blue-30);
        line-height: 160%;
        font-size: calcOutValue(12);

        p {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }

    &__items {
        display: flex;
        flex-wrap: wrap;
        gap: calcOutValue(30);
        margin-top: calcOutValue(32);

    }

    &__it {
        display: flex;
        align-items: center;
        gap: calcOutValue(8);
        color: var(--color-ui-blue-30);
        font-size: calcOutValue(16);

        svg {
            width: calcOutValue(18);
            height: calcOutValue(18);
        }
    }
}

.recent-progress {
    display: flex;
    align-items: center;
    gap: calcOutValue(10);

    &__time {
        color: var(--color-ui-dark-Blue);
        font-size: calcOutValue(12);
    }

    &__wr {
        flex: 1 1 auto;
    }
}

.propg {
    background: var(--color-ui-Blue-button-disable);
    height: calcOutValue(4);
    border-radius: calcOutValue(2);

    &_active {
        height: 100%;
        width: 0;
        border-radius: inherit;
        background: var(--color-ui-accent-color);
        position: relative;

        span {
            position: absolute;
            right: calcOutValue(-28);
            bottom: calcOutValue(6);
            width: calcOutValue(56);
            height: calcOutValue(32);
            text-align: center;
            padding-top: calcOutValue(5);
            font-size: calcOutValue(12);
            background: url(../../img/gb_progres.svg) center/contain no-repeat;
        }
    }
}

@media(max-width: 767px) {
    .cardrecent {
        padding: calcOutValue(24) calcOutValue(16);

        .aycard__panel {
            min-width: calcOutValue(119);
        }

        &__title {
            .aycard__title {
                font-size: calcOutValue(20);
                font-weight: 500;
                color: var(--color-ui-dark-Blue);
            }
        }

        &__items {
            margin-top: calcOutValue(12);
        }

        &__it {
            font-size: calcOutValue(12);
        }

        &__des {
            margin-top: calcOutValue(19);
            color: var(--color-ui-accent-color);
            font-size: calcOutValue(10);
            font-weight: 300;
            height: 33px;
            text-overflow: ellipsis;
            overflow: hidden;

            p {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }

        &__main {
            padding-bottom: 0;
        }
    }

    .recent-progress {
        &__time {
            font-size: calcOutValue(10);
        }
    }

    .propg_active span {
        font-size: calcOutValue(10);
        width: calcOutValue(32);
        right: calcOutValue(-16);
        bottom: 0.2rem;
        padding-top: 0.51667rem;
    }
}