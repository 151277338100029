.avatar-canvas {
    width: 100%;
    height: calcOutValue(226);
    position: relative;
    overflow: hidden;

    canvas {
        opacity: 0;
        // width: calcOutValue(460);
        // height: calcOutValue(226);
    }

    &__img {
        position: absolute;
        top: 50%;
        left: 0;
        // width: 100%;
        // height: 100%;
        // object-fit: cover;
        max-width: none;
    }

    &_bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #D0E0EA;
        opacity: 0.5;
    }

    &_pos {
        position: absolute;
        overflow: hidden;
        width: calcOutValue(180);
        min-width: calcOutValue(180);
        height: calcOutValue(180);
        border-radius: 100%;
        border: calcOutValue(1) solid #fff;
        left: 50%;
        margin-left: calcOutValue(-90);
        top: calcOutValue(25);

        &_wr-img {
            width: calcOutValue(460);
            height: calcOutValue(226);
            transform: translateY(calcOutValue(-25));
        }

        &_img {
            max-width: none;
            top: 50%;
            position: relative;
        }

        &_kv {
            position: absolute;
            border: calcOutValue(1) solid rgba(255, 255, 255, 0.5);
            z-index: 4;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            &_go {
                width: 110%;
                height: 40%;
            }

            &_ve {
                width: 40%;
                height: 110%;
            }
        }
    }
}

.avatar-cont {
    margin-top: calcOutValue(30);
}

.slider_range {
    .MuiSlider-root {
        width: auto;
        flex: 1 1 auto;
    }
}

.l-rang-t {
    color: var(--color-ui-dark-Blue);
    font-size: calcOutValue(12);
}

.mt-r-e {
    margin-top: calcOutValue(14);
}

.mt-wr-btn-e {
    margin-top: calcOutValue(20);
    display: flex;
    justify-content: center;
    gap: calcOutValue(20);

    .btn {
        width: auto;
        font-size: calcOutValue(16);
        line-height: 1;
        min-width: calcOutValue(140);
    }
}