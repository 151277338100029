.readout {
    font-size: calcOutValue(12);
    line-height: 160%;

    &__time {
        margin-top: calcOutValue(2);
    }
}

@media(max-width: 767px) {
    .readout {
        font-size: calcOutValue(9);
    }
}