.header {
    box-shadow: 0px 2px 18px 0px rgba(1, 107, 165, 0.12);
    background: var(--color-ui-blue-main);
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;

    &__row {
        padding: calcOutValue(24) calcOutValue(84);
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__logo {
        img {
            width: calcOutValue(142);
            height: calcOutValue(25);
        }
    }

    &__right {
        min-width: calcOutValue(142);
        display: flex;
        justify-content: flex-end;
    }
}

.menu {
    &_ul {
        display: flex;
        align-items: center;
        gap: calcOutValue(40);
    }

    a {
        text-decoration: none;
        white-space: nowrap;
        position: relative;
        color: var(--color-ui-accent-color);
        font-size: calcOutValue(18);
        transition: 0.3s;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            height: calcOutValue(1.4);
            background: var(--color-ui-accent-color);
            transition: 0.3s;
            opacity: 0;
            width: 100%;
        }

        &.active {
            &::after {
                opacity: 1;
            }
        }
    }
}

.h-profile {
    position: relative;

    &__avat {
        position: relative;
        background: var(--color-ui-blue-30);
        width: calcOutValue(40);
        height: calcOutValue(40);
        border-radius: 50%;
        border: calcOutValue(1) solid var(--color-white);
        cursor: pointer;
        display: flex;
        align-items: center;

        span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 4;
            color: var(--color-white);
            font-weight: 300;
            font-size: calcOutValue(24);
        }

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
            z-index: 4;
        }
    }

    &__main {
        position: absolute;
        top: 143%;
        right: 0;
        width: calcOutValue(310);
        padding-top: calcOutValue(62);
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: 0.1s;

        &.open {
            top: 173%;
            opacity: 1;
            visibility: visible;
            pointer-events: all;
        }
    }

    &__content {
        border-radius: calcOutValue(12);
        background: rgba(255, 255, 255, 0.36);
        backdrop-filter: blur(12px);
        box-shadow: -7px 7px 17px 0px rgba(147, 170, 194, 0.20);
        padding-top: calcOutValue(63);
        position: relative;
    }

    &__bot {
        background: var(--color-white);
        padding: calcOutValue(19) 0;
        border-radius: 0 0 calcOutValue(12) calcOutValue(12);
        display: flex;
        justify-content: center;
    }

    &__wr-ava {
        display: flex;
        justify-content: center;
        top: calcOutValue(-63);
        position: absolute;
        width: 100%;
        left: 0;
    }

    &__ava {
        width: calcOutValue(126);
        height: calcOutValue(126);
        border-radius: 50%;
        background: var(--color-ui-blue-10);
        border: calcOutValue(8) solid var(--color-white);
        position: relative;
        box-shadow: 0px 4px 4px 0px rgba(85, 118, 136, 0.20);

        span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 4;
            color: var(--color-white);
            font-weight: 400;
            font-size: calcOutValue(44);
        }

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
            z-index: 4;
        }
    }

    &__info {
        padding: 0 calcOutValue(36) calcOutValue(24) calcOutValue(39);
    }

    &__name {
        font-size: calcOutValue(20);
        color: var(--color-ui-dark-Blue);
        line-height: calcOutValue(48);
    }

    &__edit {
        position: absolute;
        width: calcOutValue(24);
        height: calcOutValue(24);
        background: transparent;
        right: calcOutValue(14);
        top: calcOutValue(15);
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: inherit;
            height: inherit;
        }
    }

    &__contact {
        margin-top: calcOutValue(14);

        &-li {
            display: flex;
            align-items: center;
            gap: calcOutValue(8);
            font-size: calcOutValue(14);
            color: #D0E0EA;
            letter-spacing: calcOutValue(-0.28);

            span {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                flex: 1 1 auto;
                max-width: 100;
                color: var(--color-ui-dark-Blue);
            }

            svg {
                min-width: calcOutValue(24);
            }

            &:not(:first-child) {
                margin-top: calcOutValue(12);
            }


        }
    }

    &__btn-exit {
        display: flex;
        align-items: center;
        font-size: calcOutValue(14);
        gap: calcOutValue(4);
        line-height: 120%;
        color: var(--color-ui-blue-30);
    }

    &__wr-bts {
        margin: calcOutValue(18) auto 0 auto;
        max-width: calcOutValue(180);

        button {
            font-size: calcOutValue(16);
        }
    }
}

@media(max-width: 767px) {
    .header {
        border-bottom: calcOutValue(1) solid #C1CAD1;
        background: #E7F1F8;
        box-shadow: none;

        &__wr-menu {
            display: none;
        }

        &__row {
            padding: calcOutValue(40) calcOutValue(20) calcOutValue(20);
            justify-content: space-between;
        }

        &__right {
            min-width: auto;
        }
    }

    .header_btn_burger {
        width: calcOutValue(40);

        button {
            width: calcOutValue(24);
            height: calcOutValue(24);

            svg {
                width: inherit;
                height: inherit;
            }
        }
    }

    .h-profile {
        &__avat {
            cursor: none;
        }
    }

    .mob-menu {
        position: fixed;
        top: calcOutValue(100);
        bottom: 0;
        z-index: 9999;
        left: -100%;
        opacity: 0;
        transition: all 0.2s ease-out;
        display: flex;
        flex-direction: column;
        width: 100%;

        &_m {
            border-radius: 0px calcOutValue(12) 0px 0px;
            background: #FFF;
            box-shadow: 7px 4px 8px 0px rgba(111, 111, 111, 0.20);
            padding: calcOutValue(36) calcOutValue(0) calcOutValue(36) calcOutValue(20);
            width: calcOutValue(244);
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;
            position: relative;
        }

        &_entering {
            opacity: 0.5;
        }

        &_entered {
            left: 0;
            opacity: 1;
        }

        &_exited {
            opacity: 0.5;
            left: -100%;
        }

        &__btn-close {
            position: absolute;
            top: calcOutValue(6);
            right: calcOutValue(4);
            z-index: 2;
            width: calcOutValue(24);
            height: calcOutValue(24);
        }

        &__content {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: calcOutValue(24);
        }

        &__bot_link {
            color: var(--color-w-blue);
            font-weight: 300;
            font-size: calcOutValue(10);
            letter-spacing: calcOutValue(-0.2);
            text-decoration: none;
        }
    }

    .mmenu {
        &__li {
            &:not(:first-child) {
                margin-top: calcOutValue(24);
            }
        }

        &__a {
            display: flex;
            align-items: center;
            padding: calcOutValue(4);
            gap: calcOutValue(8);
            color: var(--color-ui-accent-color);
            font-size: calcOutValue(16);
            text-decoration: none;
            border-radius: calcOutValue(40) 0px 0px calcOutValue(40);

            i {
                width: calcOutValue(36);
                height: calcOutValue(36);
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                background: var(--color-ui-blue-main);

                svg {
                    width: calcOutValue(20);
                    height: calcOutValue(20);
                    stroke: #537486;
                }
            }

            &.active {
                background: var(--color-ui-blue-main);

                i {
                    background: var(--color-ui-accent-color);

                    svg {
                        stroke: #fff;
                    }
                }
            }

            &.no_stro {
                i {
                    svg {
                        stroke: none;
                    }
                }
            }
        }
    }

    .bot-mob-menu {
        button {
            max-width: calcOutValue(180);
            margin-bottom: calcOutValue(26);
        }
    }
}