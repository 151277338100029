.cookie {
    width: calcOutValue(342);
    position: absolute;
    border-radius: calcOutValue(12);
    background: #334E5E;
    padding: calcOutValue(32);
    text-align: center;
    color: #fff;
    z-index: 30;
    left: calcOutValue(84);
    bottom: calcOutValue(36);

    &__close-btn {
        width: calcOutValue(24);
        height: calcOutValue(24);
        position: absolute;
        right: calcOutValue(10);
        top: calcOutValue(10);

        svg {
            width: inherit;
            height: inherit;
        }
    }

    &__title {
        font-weight: 600;
        line-height: 140%;
        font-size: calcOutValue(14);
    }

    &__des {
        margin-top: calcOutValue(12);
        line-height: 160%;
        font-size: calcOutValue(12);
    }

    &__btn_w {
        display: flex;
        justify-content: center;
        margin-top: calcOutValue(24);
    }

    &__btn {
        border-radius: calcOutValue(6);
        background-color: #fff;
        color: #334E5E;
        font-size: calcOutValue(14);
        padding: 0 calcOutValue(14);
        height: calcOutValue(32);
        font-weight: 600;
        line-height: 140%;
    }
}

.cookie_opas {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(175, 175, 175, 0.55);
    ;
    z-index: 20;
}

@media(max-width: 767px) {
    .cookie {
        position: fixed;
        width: 100%;
        border-radius: 0;
        bottom: 0;
        left: 0;
    }
}