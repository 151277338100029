@import "./global/vars";
@import "./rules/function";
@import "./global/fonts";
@import "./global/base";
@import "./global/pages";
@import "./components/header";
@import "./components/fieldInput";
@import "./components/checkedTerms";
@import "./components/button";
@import "./components/readout";
@import "./components/avatar";
@import "./components/modals";
@import "./components/authorization";
@import "./components/banner";
@import "./components/filter";
@import "./components/nolist";
@import "./components/cardAudio";
@import "./components/select";
@import "./components/terms";
@import "./components/books";
@import "./components/bookInfo";
@import "./components/bookSection";
@import "./components/accordion";
@import "./components/player";
@import "./components/cardRecent";
@import "./layouts/players";
@import "./components/settings";
@import "./components/editAvatarCanvas";
@import "./components/notofication";
@import "./components/_coocies";
@import "./components/pagination";
@import "./components/faq";

/*Убрать полосу прокрутки у элемента*/
html::-webkit-scrollbar {
    width: 0;
}

/*Убрать полосу прокрутки для всей страницы*/
html::-webkit-scrollbar {
    width: 0;
}