.ava {}

.avat {
    display: flex;
    border-radius: 50%;
    background: var(--color-white);
    border: calcOutValue(2) solid var(--color-white);
    overflow: hidden;

    &__lab {
        display: flex;
        width: calcOutValue(80);
        height: calcOutValue(80);
        border-radius: inherit;
        background: url(../../icons/user_avatar.svg) center/28% no-repeat, var(--color-ui-blue-30);
        position: relative;
        cursor: pointer;

        input {
            display: none;
        }
    }

    &__t {
        position: absolute;
        text-align: center;
        display: block;
        left: calcOutValue(-2);
        right: calcOutValue(-2);
        bottom: calcOutValue(-2);
        background: var(--color-ui-blue-10);
        z-index: 4;
        color: var(--color-ui-accent-color);
        font-size: calcOutValue(6);
        white-space: nowrap;
        padding: calcOutValue(4) calcOutValue(4) calcOutValue(9);
    }

    &__img {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: inherit;
        object-fit: cover;
        z-index: 3;
    }

    &.active {
        .avat__t {
            color: #fff;
            font-size: calcOutValue(14);
            font-weight: 400;
        }
    }
}


@media(max-width: 767px) {
    .avat {
        &__lab {
            width: calcOutValue(68);
            height: calcOutValue(68);
        }
    }
}