.banner {
    &__row {
        display: flex;
        align-items: center;
        gap: calcOutValue(20);
    }

    &__img {
        width: calcOutValue(198);
        min-width: calcOutValue(198);
        line-height: 0;

        img {
            width: 100%;
        }
    }

    &__title {
        font-size: calcOutValue(40);
        color: var(--color-ui-accent-color);
        font-weight: 400;
    }

    &__des {
        font-family: 'montserrat';
        font-size: calcOutValue(14);
        line-height: calcOutValue(20);
        font-weight: 300;
        margin-top: calcOutValue(6);
    }
}


@media(max-width: 767px) {
    .banner {
        display: none;
    }
}