.nolist {
    border-radius: calcOutValue(20);
    padding: calcOutValue(120) calcOutValue(40);
    background: var(--color-ui-blue-main);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &__bg {
        width: calcOutValue(213);
        height: calcOutValue(275);

        svg {
            width: inherit;
            height: inherit;
        }
    }

    &__cont {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: calcOutValue(475);
    }

    &__title {
        font-size: calcOutValue(24);
        color: var(--color-ui-accent-color);
        letter-spacing: calcOutValue(-0.48);
    }

    &__des {
        margin-top: calcOutValue(10);
        font-size: calcOutValue(18);
        font-weight: 200;
    }
}

.card_load {
    width: 100%;
    border-radius: calcOutValue(20);
    height: calcOutValue(280);
    position: relative;
    background: linear-gradient(123deg, rgba(232, 242, 249, 0.24) 3.93%, rgba(234, 243, 249, 0.24) 17.5%, rgba(165, 212, 242, 0.24) 32.34%, rgba(169, 226, 229, 0.24) 42.51%, rgba(232, 242, 249, 0.24) 57.04%, rgba(212, 231, 245, 0.24) 66.63%), #E7F1F8;
}

@media(max-width: 767px) {
    .nolist {
        padding: calcOutValue(100) calcOutValue(20) calcOutValue(100);
        background: linear-gradient(180deg, #FFF 0%, #EEF8FF 100%);
        border: calcOutValue(1) solid #D0E0EA;
        border-radius: calcOutValue(12);

        &__bg {
            width: calcOutValue(107);
            height: calcOutValue(138);
        }

        &__cont {
            max-width: calcOutValue(260);
            width: 100%;
            top: 42%;
        }

        &__title {
            font-size: calcOutValue(14);
            font-weight: 500;
        }

        &__des {
            font-size: calcOutValue(12);
            font-weight: 300;
        }
    }

    .card_load {
        height: calcOutValue(220);
    }
}

.card_load_recent {
    &:not(:first-child) {
        margin-top: calcOutValue(20);
    }
}