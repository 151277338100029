.field-wr {
    position: relative;
}

.field-input {
    font-family: 'inter', sans-serif;
    outline: none;
    transition: 0.4s;
    border: calcOutValue(1) solid var(--color-ui-Blue-button-disable);
    padding: calcOutValue(10) calcOutValue(20);
    width: 100%;
    height: calcOutValue(42);
    border-radius: calcOutValue(8);
    font-size: calcOutValue(14);
    font-weight: 400;
    color: var(--color-ui-dark-Blue);
    background: transparent;

    &::placeholder {
        color: var(--color-ui-Blue-button-disable);
        font-size: calcOutValue(14);
    }

    &::-moz-placeholder {
        color: var(--color-ui-Blue-button-disable);
        font-size: calcOutValue(14);
    }

    &::-webkit-input-placeholder {
        color: var(--color-ui-Blue-button-disable);
        font-size: calcOutValue(14);
    }

    &:focus {
        border-color: var(--color-ui-dark-Blue);
    }

    &.error {
        color: var(--color-w-red);
    }
}

.field-message-error {
    font-size: calcOutValue(10);
    line-height: 120%;
    color: var(--color-w-red);
    font-family: 'inter', sans-serif;
}

.field-message_in {
    line-height: 120%;
    font-size: calcOutValue(10);
    color: var(--color-ui-accent-color);

    &.error_message_in {
        color: var(--color-w-red);
    }
}

.form-er-in-t {
    font-size: calcOutValue(12);
    color: var(--color-ui-dark-Blue);
    line-height: 160%;
}

.field-input_text-area {
    height: calcOutValue(142);
    resize: none;
}

@media(max-width: 767px) {
    .field-input {
        font-size: calcOutValue(12);

        &::placeholder {
            font-size: calcOutValue(12);
        }

        &::-moz-placeholder {
            font-size: calcOutValue(12);
        }

        &::-webkit-input-placeholder {
            font-size: calcOutValue(12);
        }
    }
}