.book-player-wr {
    position: fixed;
    z-index: 100;
    left: 0;
    bottom: -100%;
    width: 100%;
    background: rgba(242, 247, 251, 0.95);
    backdrop-filter: blur(calcOutValue(4));
    padding: calcOutValue(24) 0;
    transition: 0.2s;
}

.player-book {
    display: none;
    pointer-events: none;
    width: 0;
    height: 0;
}

.play {
    &-inform {
        display: flex;
        align-items: center;
        gap: calcOutValue(20);

        &__time {
            color: var(--color-ui-dark-Blue);
            line-height: 120%;
            font-size: calcOutValue(16);
            font-weight: 400;

            &_plus {
                min-width: calcOutValue(58);
                text-align: left;
            }

            &_minus {
                min-width: calcOutValue(58);
                text-align: right;
            }
        }

        &__progress {
            flex: 1 1 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            svg {
                width: 100%;
                height: calcOutValue(25);
                fill: #D0E0EA;
            }
        }
    }

    &.no-track {
        opacity: 0.4;
        pointer-events: none;
    }
}

.play-panel {
    display: flex;
    align-items: center;
    margin-top: calcOutValue(20);
    justify-content: space-between;
    gap: calcOutValue(20);

    &__left {
        display: flex;
        align-items: center;
        gap: calcOutValue(24);
    }

    &__col {
        min-width: calcOutValue(218);
    }

    &__center {
        display: flex;
        align-items: center;
        gap: calcOutValue(32);
    }

    &__right {
        display: flex;
        justify-content: flex-end;
    }
}

.btn-play-24 {
    width: calcOutValue(24);
    height: calcOutValue(24);

    svg {
        width: inherit;
        height: inherit;
    }
}

.btn-play-48 {
    width: calcOutValue(48);
    height: calcOutValue(48);

    svg {
        width: inherit;
        height: inherit;
    }
}

.slider-volume {
    display: flex;
    align-items: center;
    gap: calcOutValue(18);

    .MuiSlider-root {
        width: calcOutValue(134);

        .MuiSlider-rail {
            background: var(--color-ui-Blue-button-disable);
            height: calcOutValue(4);
        }

        .MuiSlider-track {
            background: var(--color-ui-accent-color);
            height: calcOutValue(4);
            border: none;
        }

        .css-eg0mwd-MuiSlider-thumb {
            width: calcOutValue(20);
            height: calcOutValue(20);
            background: #537486 !important;
            box-shadow: none !important;

            &::after {
                width: calcOutValue(24);
                height: calcOutValue(24);
            }
        }
    }

    &__btn {
        width: calcOutValue(24);
        height: calcOutValue(24);

        svg {
            width: inherit;
            height: inherit;
        }
    }
}

.book-player-close {
    position: absolute;
    top: calcOutValue(23);
    right: calcOutValue(16);
    width: calcOutValue(24);
    height: calcOutValue(24);
    z-index: 6;

    svg {
        width: inherit;
        height: inherit;
    }
}

@media(max-width: 767px) {
    .play-panel {
        margin-top: calcOutValue(10);
        gap: 0;
        flex-direction: column;

        &__col {
            min-width: auto;
        }

        &__mob {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
    }

    .play-inform {
        &__time {
            font-size: calcOutValue(12);
            min-width: calcOutValue(36);
        }
    }

    .book-player-wr {
        border-radius: calcOutValue(20) calcOutValue(20) 0 0;
        padding: calcOutValue(12) 0 calcOutValue(8);
    }

    .book-player-close {
        display: none;
    }
}