.layout-player-book {
    transition: 0.2s;

    &.show_player {
        padding-bottom: calcOutValue(140);

        .book-player-wr {
            bottom: 0;
        }

        .bot-wr-add {
            bottom: calcOutValue(158);
        }
    }
}

@media(max-width: 767px) {
    .bot-wr-add {
        display: none;
    }
}