.btn {
    position: relative;
    background: var(--color-ui-accent-color);
    color: var(--color-white);
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    min-height: calcOutValue(44);
    align-items: center;
    font-size: calcOutValue(18);
    padding: calcOutValue(7) calcOutValue(18);
    border: calcOutValue(1) solid var(--color-ui-accent-color);
    border-radius: calcOutValue(8);
    transition: 0.3s;

    &:hover {
        background: var(--color-ui-h);
        border-color: var(--color-ui-h);
    }

    &:disabled {
        background: var(--color-ui-Blue-button-disable) !important;
        border-color: var(--color-ui-Blue-button-disable);
        pointer-events: none;
    }

    &_b {
        background: transparent !important;
        border-color: var(--color-ui-accent-color);
        color: var(--color-ui-accent-color);

        &:hover {
            border-color: var(--color-ui-Blue-button-disable);
            color: var(--color-ui-Blue-button-disable);
        }
    }

    &.load {
        span {
            opacity: 0;
        }

        &::before {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            width: calcOutValue(24);
            height: calcOutValue(24);
            margin-left: calcOutValue(-12);
            margin-top: calcOutValue(-12);
            background: url(../../icons/load.svg) center/contain no-repeat;
            z-index: 10;
            animation: loadBtn 1s linear infinite;
        }
    }
}

@keyframes loadBtn {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

.btn-text {
    color: var(--color-w-blue);
    background: transparent;
    border: none;
    font-size: inherit;
    letter-spacing: inherit;
    padding: 0;
    font-weight: inherit;
    font-family: inherit;

    &:hover {
        text-decoration: underline;
    }
}

@media(max-width: 767px) {
    .btn {
        font-size: calcOutValue(16);
        font-weight: 400;
    }
}