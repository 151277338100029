.global-page-ptop {
    margin-top: calcOutValue(46);
}

.container {
    padding: 0 calcOutValue(220);
}

.wr-grid-audio {
    margin-top: calcOutValue(34);

    &_cont {
        min-height: calcOutValue(250);
    }

    &__recent {
        padding-bottom: calcOutValue(40);
        position: relative;

        // &::before {
        //     content: '';
        //     position: absolute;
        //     z-index: -1;
        //     width: 50%;
        //     height: 60%;
        //     border-radius: 5.75rem;
        //     background: rgba(97, 142, 184, 0.1);
        //     -webkit-backdrop-filter: blur(12px);
        //     backdrop-filter: blur(12px);
        //     top: 50%;
        //     left: 50%;
        //     transform: translate(-50%, -50%);
        // }
    }
}

.page-p-b {
    padding-bottom: calcOutValue(112);
}

.bot-wr-add {
    position: fixed;
    right: calcOutValue(220);
    bottom: calcOutValue(36);
    z-index: 14;
    transition: 0.2s;

    button {
        min-height: calcOutValue(78);
        padding-left: calcOutValue(25);
        padding-right: calcOutValue(25);
        border-radius: calcOutValue(12);
        box-shadow: 4px 4px 20px 0px rgba(140, 168, 188, 0.54);
    }
}

.grid-cards-aydio {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    gap: calcOutValue(20);
    position: relative;

    // &::before {
    //     content: '';
    //     position: absolute;
    //     z-index: -1;
    //     width: 63%;
    //     height: 70%;
    //     border-radius: calcOutValue(69);
    //     background: rgba(97, 142, 184, 0.1);
    //     backdrop-filter: blur(12px);
    //     top: 50%;
    //     left: 50%;
    //     transform: translate(-50%, -50%);
    // }
}

.abook {
    padding-top: calcOutValue(46);
    min-height: calc(100vh - 5.107rem);
    display: flex;
    flex-direction: column;

    .container {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
    }
}

.load-page {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999999999;
    background: #fff;

    &::before {
        content: '';
        position: absolute;
        width: 6vw;
        height: 6vw;
        top: 50%;
        left: 50%;
        margin-left: -3vw;
        margin-top: -3vw;
        z-index: 2222222;
        background: url(../../icons/load.svg) center/contain no-repeat;
        animation: loadPage 1s linear infinite;
    }
}

@keyframes loadPage {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media(max-width: 767px) {
    .container {
        padding-left: calcOutValue(20);
        padding-right: calcOutValue(20);
    }

    .page-p-b {
        margin-top: calcOutValue(28);
        padding-bottom: calcOutValue(20);
    }

    .global-page-ptop {
        margin-top: calcOutValue(28);
    }

    .grid-cards-aydio {
        grid-template-columns: 1fr;
        gap: calcOutValue(18);

        &::before {
            display: none;
        }
    }

    .wr-grid-audio__recent::before {
        display: none;
    }

    .abook {
        padding-top: calcOutValue(28);
        min-height: auto;
        background: #fff;
    }
}