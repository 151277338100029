.pagination {
    display: flex;
    justify-content: center;
    margin-top: calcOutValue(50);

    &_wr {
        display: flex;
        gap: calcOutValue(12);
        align-items: center;
    }

    &__btn {
        width: calcOutValue(24);
        height: calcOutValue(24);

        svg {
            width: inherit;
            height: inherit;
        }

        &:disabled {
            opacity: 0.4;
            pointer-events: none;
        }
    }

    &__page {
        min-width: calcOutValue(32);
        height: calcOutValue(32);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: calcOutValue(4);
        font-weight: 500;
        font-size: calcOutValue(12);
        color: var(--color-ui-accent-color);

        &.active {
            color: #fff;
            background: var(--color-ui-accent-color);
            ;
        }
    }
}