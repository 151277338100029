:root {
    --color-white: #fff;
    --color-blue-logo: #14A5F4;
    --color-book-gradient: ;
    --color-ui-accent-color: #537486;
    --color-ui-blue-main: #E7F1F8;
    --color-ui-blue-30: #9DB9CB;
    --color-ui-bottom-menu: #618EB80A;
    --color-ui-Blue-button-disable: #C1CAD1;
    --color-ui-dark-Blue: #334E5E;
    --color-ui-linear-main: linear-gradient(313deg, #E7F1F8 -8.9%, #E7F1F8 -8.89%, #EBF3F9 46.46%, #F7FAFD 100%);
    --color-yellow-logo: #FCEA45;
    --color-ui-blue-10: #D0E0EA;
    --color-whit-20: #F1F7FB;
    --color-w-red: #C25151;
    --color-w-blue: #275CB7;
    --color-ui-h: #32566A;
}