// montserrat
@font-face {
    font-family: 'montserrat';
    src: url('../../fonts/Montserrat-Regular.woff2') format('woff2'),
        url('../../fonts/Montserrat-Regular.woff') format('woff'),
        url('../../fonts/Montserrat-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'montserrat';
    src: url('../../fonts/Montserrat-Light.woff2') format('woff2'),
        url('../../fonts/Montserrat-Light.woff') format('woff'),
        url('../../fonts/Montserrat-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

// inter
@font-face {
    font-family: 'inter';
    src: url('../../fonts/Inter-Thin.woff2') format('woff2'),
        url('../../fonts/Inter-Thin.woff') format('woff'),
        url('../../fonts/Inter-Thin.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'inter';
    src: url('../../fonts/Inter-Light.woff2') format('woff2'),
        url('../../fonts/Inter-Light.woff') format('woff'),
        url('../../fonts/Inter-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'inter';
    src: url('../../fonts/Inter-Regular.woff2') format('woff2'),
        url('../../fonts/Inter-Regular.woff') format('woff'),
        url('../../fonts/Inter-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'inter';
    src: url('../../fonts/Inter-Medium.woff2') format('woff2'),
        url('../../fonts/Inter-Medium.woff') format('woff'),
        url('../../fonts/Inter-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'inter';
    src: url('../../fonts/Inter-SemiBold.woff2') format('woff2'),
        url('../../fonts/Inter-SemiBold.woff') format('woff'),
        url('../../fonts/Inter-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'inter';
    src: url('../../fonts/Inter-Bold.woff2') format('woff2'),
        url('../../fonts/Inter-Bold.woff') format('woff'),
        url('../../fonts/Inter-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
