.b-sec {
    &__title {
        font-size: calcOutValue(24);
        font-style: normal;
        font-weight: 300;
        line-height: calcOutValue(48);
        text-transform: uppercase;
        span {
            font-weight: 400;
        }
    }

    &__accard {
        margin-top: calcOutValue(5);
    }
}

@media(max-width: 767px) {
    .b-sec {
        margin-top: calcOutValue(20);
        &__title {
            font-size: 16px;
            color: var(--color-ui-blue-30);
            span {
                font-weight: 500;
                color: var(--color-ui-dark-Blue);
            }
        }
    }
}