.books {
    background: var(--color-white);
    border-radius: calcOutValue(12);
    position: relative;
    box-shadow: 8px 8px 12px 0px rgba(48, 96, 123, 0.07);
    flex: 1 1 auto;
    padding-bottom: calcOutValue(40);

    &_container_content {
        padding-left: calcOutValue(110);
        padding-right: calcOutValue(110);
    }

    min-height: 80vh;
}

.load-book {
    padding: calcOutValue(48) calcOutValue(110);
    margin: 0 auto;

    &_card {
        border-radius: calcOutValue(20);
        background: linear-gradient(123deg, rgba(245, 248, 250, 0.24) 3.93%, rgba(234, 243, 249, 0.24) 17.5%, rgba(165, 212, 242, 0.24) 32.34%, rgba(169, 226, 229, 0.24));
    }

    &__top {
        display: flex;
        gap: calcOutValue(25);

        &_left {
            width: calcOutValue(300);
        }

        &_right {
            flex: 1 1 auto;

            &__top {
                height: calcOutValue(120);
            }

            &__bot {
                height: calcOutValue(256);
                margin-top: calcOutValue(45);
            }
        }
    }

    &__bot {
        margin-top: calcOutValue(137);
        height: calcOutValue(386)
    }
}

@media(max-width: 767px) {
    .books {
        box-shadow: none;
    }

    .load-book {
        padding: calcOutValue(10) calcOutValue(10);

        &__top {
            flex-direction: column;
            gap: calcOutValue(30);

            &_left {
                width: 100%;
                height: calcOutValue(200);
            }

            &_right {
                flex: 1 1 auto;

                &__top {
                    height: calcOutValue(60);
                }

                &__bot {
                    height: calcOutValue(128);
                    margin-top: calcOutValue(30);
                }
            }
        }

        &__bot {
            margin-top: calcOutValue(80);
            height: calcOutValue(180)
        }
    }
}