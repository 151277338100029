.modal-bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    opacity: 0.6;
    background: var(--color-ui-blue-10);
}

.hystmodal {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    z-index: 99999;
    padding: 1.666rem 0;

    ::-webkit-scrollbar,
    ::-webkit-scrollbar-thumb {
        width: 0;
        height: 0;
    }

    &__wrap {
        flex-shrink: 0;
        flex-grow: 0;
        width: 100%;
        min-height: 100%;
        margin: auto;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        padding: 0 14px;
    }

    &__window {
        margin: 50px 0;
        flex-shrink: 0;
        flex-grow: 0;
        background: linear-gradient(180deg, #FFF 0%, #E5F4FF 100%);
        max-width: 100%;
        transition: transform 0.2s ease 0s, opacity 0.2s ease 0s;
        position: relative;
        z-index: 100;
        width: calcOutValue(560);
        border-radius: calcOutValue(12);
        padding-top: calcOutValue(50);
        border: calcOutValue(1) solid var(--color-ui-blue-10);
        min-height: calcOutValue(380);
    }

    &__op {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.6;
        background: var(--color-ui-blue-10);
    }

    &__content {
        padding: calcOutValue(22) calcOutValue(40) calcOutValue(20) calcOutValue(40);
    }

    &__close {
        width: calcOutValue(24);
        height: calcOutValue(24);
        position: absolute;
        right: calcOutValue(18);
        top: calcOutValue(15);
        padding: 0;
        z-index: 5;
    }

    &_avatar {
        z-index: 9999;

        .hystmodal__window {
            width: calcOutValue(460);
            padding: calcOutValue(32) 0 calcOutValue(36) 0;
            background: #fff;
        }

        .hystmodal__content {
            padding: 0;
        }

        .avat__t {
            font-size: calcOutValue(14);
            font-weight: 600;
            line-height: 140%;
            padding-top: calcOutValue(11);
            padding-bottom: calcOutValue(23);
        }
    }
}

.modal {
    &__title {
        color: var(--color-ui-dark-Blue);
        font-size: calcOutValue(20);
        line-height: 120%;

        &_icon {
            position: relative;
            padding: calcOutValue(28) 0 calcOutValue(32) calcOutValue(27);

            span {
                position: relative;
                z-index: 5;
            }

            &::before {
                content: '';
                position: absolute;
                width: calcOutValue(60);
                height: calcOutValue(76);
                background: url(../../icons/book.svg) center/contain no-repeat;
                left: 0;
                top: 0;
            }
        }
    }

    &-content {
        max-width: calcOutValue(360);
        margin: 0 auto;

        &_386 {
            max-width: calcOutValue(410);
        }

        &_478 {
            max-width: calcOutValue(487);
        }
    }

    &__two__btn {
        display: flex;
        gap: calcOutValue(12);
        max-width: calcOutValue(292);
        width: 100%;
        margin-left: auto;
        margin-right: auto;

        div {
            width: calc(50% - 0.5rem);
        }
    }
}

.g-modal-20 {
    display: flex;
    flex-direction: column;
    gap: calcOutValue(20);
}

.m-f-t {
    padding-top: calcOutValue(54);
}

.modal-form {
    .field-message-error {
        font-size: calcOutValue(12);
        margin-top: calcOutValue(2);
    }
}

.modal-wr-btn-one {
    max-width: calcOutValue(300);
    margin: 0 auto;
    width: 100%;
}

.wr-modal-title {
    display: flex;
    justify-content: center;
}

.mod-ava {
    &__title {
        color: var(--color-ui-dark-Blue);
        font-size: calcOutValue(18);
        font-weight: 500;
        line-height: 150%;
        letter-spacing: calcOutValue(0.27);
    }

    &__des {
        margin-top: calcOutValue(6);
        font-size: calcOutValue(14);
        letter-spacing: calcOutValue(-0.28);
    }

    &___wr {
        margin-top: calcOutValue(30);

        .avat {
            width: calcOutValue(260);
            height: calcOutValue(260);
            margin-left: auto;
            margin-right: auto;

            .avat__lab {
                width: inherit;
                height: inherit;
            }
        }
    }

    &__pad {
        padding-left: calcOutValue(40);
        padding-right: calcOutValue(40);
    }

    &__wr-btn {
        display: flex;
        justify-content: center;
        margin-top: calcOutValue(40);

        .btn {
            font-size: calcOutValue(16);
            line-height: 1;
            width: auto;
            min-width: calcOutValue(140);
        }
    }
}

.mod-ava_error {
    font-size: calcOutValue(12);
    margin-top: calcOutValue(14);
    color: var(--color-w-red);
    text-align: center;
    padding: 0 calcOutValue(40);
}

.inf-down {
    text-align: center;
    font-size: calcOutValue(12);
}

.form-faq {
    display: flex;
    flex-direction: column;
    gap: calcOutValue(10);
}

.bot-modal-p {
    padding-bottom: calcOutValue(20);
}

@media(max-width: 767px) {
    .m-f-t {
        padding-top: calcOutValue(19);
    }

    .hystmodal {
        &__close {
            top: calcOutValue(6);
            right: calcOutValue(8);
        }

        &__content {
            padding-left: calcOutValue(20);
            padding-right: calcOutValue(20);
        }

        &__window {
            min-height: calcOutValue(320);
        }
    }

    .modal {
        &__title {
            font-weight: 600;
            font-size: calcOutValue(14);
        }
    }

    .mod-ava {
        &__title {
            font-size: calcOutValue(16);
        }

        &__des {
            font-size: calcOutValue(12);
            color: var(--color-ui-dark-Blue);
        }

        &___wr {
            .avat {
                width: calcOutValue(182);
                height: calcOutValue(182);
            }
        }

        &__pad {
            padding-left: calcOutValue(20);
            padding-right: calcOutValue(20);
        }

        &___wr {
            margin-top: calcOutValue(14);
        }

        &__wr-btn {
            margin-top: calcOutValue(14);
        }

        .mod-ava___wr {
            .avat__t {
                font-size: calcOutValue(10);
                padding-top: calcOutValue(12);
                bottom: calcOutValue(2);
            }
        }

        .css-187mznn-MuiSlider-root {
            padding: 11px 0;
        }
    }

    .m-f-t {
        padding-top: 0;
    }

    .modal-wr-btn-one {
        max-width: calcOutValue(240);
    }


}