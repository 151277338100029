.filter {
    margin-top: calcOutValue(64);

    &__row {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    &__title {
        letter-spacing: calcOutValue(-0.48);
        font-size: calcOutValue(24);
        color: var(--color-ui-accent-color);
        text-transform: uppercase;
    }
}

.fm {
    display: flex;
    justify-content: flex-end;

    &-item {
        position: relative;

        &_btn {
            padding: calcOutValue(7) calcOutValue(17);
            height: calcOutValue(32);
            font-size: calcOutValue(12);
            line-height: 120%;
            color: var(--color-ui-accent-color);
            cursor: pointer;
            text-align: center;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: calcOutValue(124);

            span {
                position: relative;
                z-index: 10;
                line-height: 120%;
                transition: 0.2s;
            }

            i {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 99%;

                svg {
                    @media(min-width: 767px) {
                        stroke: #537486;
                        stroke-width: calcOutValue(0.6);
                        fill: transparent;
                    }

                    transition: 0.4s;
                    width: 100%;
                    height: 100%;
                }
            }

            // &:hover {
            //     @media(min-width: 767px) {
            //         span {
            //             color: var(--color-white);
            //         }

            //         i {
            //             svg {
            //                 fill: var(--color-ui-accent-color);
            //             }
            //         }
            //     }
            // }
        }

        &_m {
            position: absolute;
            left: 0;
            top: 100%;
            width: calcOutValue(102);
            opacity: 0;
            pointer-events: none;
            visibility: hidden;
            transition: 0.4s;
            z-index: 40;

            &_d {
                margin-top: 2%;
                // background: var(--color-ui-accent-color);
                border: calcOutValue(1) solid var(--color-ui-accent-color);
                border-radius: calcOutValue(4);
                padding: calcOutValue(12) calcOutValue(8) calcOutValue(24) calcOutValue(8);
            }

            li {
                &:not(:first-child) {
                    margin-top: calcOutValue(8);
                }
            }

            button {
                color: var(--color-ui-accent-color);
                letter-spacing: calcOutValue(-0.2);
                font-size: calcOutValue(10);
                font-weight: 300;
                text-align: left;

                &.active {
                    text-decoration: underline;
                }
            }
        }

        &.type_one {
            .fm-item_btn {
                padding-right: calcOutValue(34);
            }
        }

        &.type_two {
            .fm-item_btn {
                padding-left: calcOutValue(28);
                padding-right: calcOutValue(28);
            }

            .fm-item_m {
                width: calcOutValue(120);
            }
        }

        &.type_three {
            .fm-item_btn {
                padding-left: calcOutValue(35);
            }

            .fm-item_m {
                width: 100%;
            }
        }

        &_four {
            margin-right: calcOutValue(-14);
        }

        &_three {
            margin-right: calcOutValue(4);
        }

        &_two {
            margin-right: calcOutValue(-14);
        }

        &_one {
            margin-right: calcOutValue(-14);
        }

        &:hover {
            @media (min-width: 767px) {
                .fm-item_btn {
                    span {
                        color: var(--color-white);
                    }
    
                    i {
                        svg {
                            fill: var(--color-ui-accent-color);
                        }
                    }
                }

                .fm-item_m_d {
                    background: var(--color-ui-accent-color);
                }

                .fm-item_m {
                    pointer-events: all;
                    visibility: visible;
                    opacity: 1;
                }

                .fm-item_m button {
                    color: var(--color-white);
                }
            }
        }

        &.non {
            pointer-events: none;

            .fm-item_btn {
                span {
                    color: var(--color-white);
                }

                i {
                    svg {
                        @media(min-width: 767px) {
                            stroke: var(--color-ui-Blue-button-disable);
                        }

                        fill: var(--color-ui-Blue-button-disable);
                    }
                }
            }

            .fm-item_m {
                display: none;
            }
        }

        &.active {
            .fm-item_btn {
                span {
                    color: var(--color-white);
                }

                i {
                    svg {
                        fill: var(--color-ui-accent-color);
                    }
                }
            }

            .fm-item_m_d {
                background: var(--color-ui-accent-color);
            }

            .fm-item_m button {
                color: var(--color-white);
            }
        }

        &.open {
            .fm-item_btn {
                span {
                    color: var(--color-white);
                }

                i {
                    svg {
                        fill: var(--color-ui-accent-color);
                    }
                }
            }

            .fm-item_m {
                pointer-events: all;
                visibility: visible;
                opacity: 1;
            }
        }
    }
}


@media(max-width: 767px) {
    .filter {
        margin-top: calcOutValue(28);

        &__row {
            flex-direction: column;
        }

        &__title {
            font-weight: 500;
            color: var(--color-ui-dark-Blue);
        }
    }

    .fm-wr {
        margin-top: calcOutValue(18);
    }

    .fm {
        flex-wrap: wrap;
        justify-content: flex-start;

        &-item_btn {
            height: calcOutValue(24);
            min-width: calcOutValue(120);
            color: #fff;
            font-size: calcOutValue(12);
            font-weight: 400;
            line-height: 120%;
            position: relative;
            text-align: center;

            i {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 99%;

                svg {
                    width: 100%;
                    height: 100%;
                    fill: #D0E0EA;
                }
            }
        }

        &-item {
            &_one {
                margin-right: calcOutValue(-18);
            }

            &_two {
                min-width: calcOutValue(140);
                margin-right: 0;
            }

            &_three {
                margin-right: 0;
                margin-left: calcOutValue(-18);
            }

            &_four {
                margin-right: calcOutValue(-18);
            }

            &_four,
            &_five {
                margin-top: calcOutValue(6);
            }

            &_m {
                width: 8.1rem;
            }
        }
    }

    .fm-item_btn {
        cursor: auto;
    }
}