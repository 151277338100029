.settings {
    padding-top: calcOutValue(110);
    padding-bottom: calcOutValue(50);

    &__content {
        padding-top: calcOutValue(208);
        padding-bottom: calcOutValue(48);
        border-radius: calcOutValue(12);
        border: calcOutValue(1) solid #D0E0EA;
        background: linear-gradient(273deg, #FFF 2.79%, #E5F0F8 97.29%);
        box-shadow: -4px 4px 7px 0px rgba(64, 117, 151, 0.12);
        position: relative;
    }

    &__ava {
        position: absolute;
        left: 50%;
        top: calcOutValue(-75);
        margin-left: calcOutValue(-130);

        &>.ava {
            &>.avat {
                border-width: calcOutValue(6);

                .avat__lab {
                    width: calcOutValue(260);
                    height: calcOutValue(260);
                    background-color: #C1CAD1;
                }

                .avat__t {
                    font-size: calcOutValue(14);
                    padding: calcOutValue(13) calcOutValue(6) calcOutValue(22) calcOutValue(6);
                    background: rgba(255, 255, 255, .6);
                    font-weight: 500;
                }
            }


        }
    }

    &__btn_back {
        position: absolute;
        width: calcOutValue(40);
        height: calcOutValue(40);
        top: calcOutValue(10);
        left: calcOutValue(20);

        svg {
            width: inherit;
            height: inherit;
        }
    }

    &__name {
        color: var(--color-ui-dark-Blue);
        letter-spacing: calcOutValue(-0.48);
        font-size: calcOutValue(24);
    }

    &__form {
        margin: calcOutValue(48) auto 0;
        max-width: calcOutValue(692);
        display: flex;
        flex-direction: column;
        gap: calcOutValue(12);

        &--t {
            font-size: calcOutValue(16);
        }

        &__row {
            display: flex;
            gap: calcOutValue(12);

            .field-wr {
                flex: 1 1 auto;
            }
        }
    }

    &__btn_send {
        display: flex;
        justify-content: center;
        margin-top: calcOutValue(30);

        .btn {
            width: auto;
            min-width: calcOutValue(140);
        }
    }

    &__btn_exit {
        margin-top: calcOutValue(30);
        display: flex;
        justify-content: center;

        .h-profile__btn-exit {
            font-size: calcOutValue(16);
        }
    }
}

.wr-btn-link-p {
    display: none;
}

@media(max-width: 767px) {
    .settings {
        padding-top: 8.16667rem;
        padding-bottom: calcOutValue(36);

        &__btn_back {
            display: none;
        }

        &__content {
            border: none;
            border-radius: none;
            background: transparent;
            box-shadow: none;
            padding-top: 15.33333rem;
            padding-bottom: 0;
        }

        &__ava {
            margin-left: calcOutValue(-116);

            &>.ava {
                &>.avat {
                    .avat__lab {
                        width: calcOutValue(232);
                        height: calcOutValue(232);
                    }
                }
            }
        }

        &__name {
            font-size: alcOutValue(16);
        }

        &__form {
            margin-top: calcOutValue(18);

            &--t {
                text-align: center;
                font-size: calcOutValue(14);
                padding-bottom: calcOutValue(16);
            }

            &__row {
                flex-direction: column;
            }
        }
    }

    .wr-btn-link-p {
        text-align: center;
        margin-top: calcOutValue(80);
        display: block;

        a {
            font-size: calcOutValue(10);
            text-decoration: none;
            color: var(--color-w-blue);
            font-weight: 300;
        }
    }
}