.notification {
    position: fixed;
    z-index: 100;
    top: calcOutValue(102);
    right: calcOutValue(220);
    width: calcOutValue(358);
    display: flex;
    flex-direction: column;
    gap: calcOutValue(14);
}

.notitem {
    position: relative;
    border-radius: calcOutValue(8);
    background: var(--color-ui-accent-color);
    box-shadow: 8px 8px 12px 0px rgba(48, 96, 123, 0.07);
    padding: calcOutValue(26) calcOutValue(56);
    color: #fff;

    &__close {
        position: absolute;
        width: calcOutValue(24);
        height: calcOutValue(24);
        right: calcOutValue(7);
        top: calcOutValue(4);
    }

    &_cont {
        display: flex;
        align-items: center;
        gap: calcOutValue(8);
        font-size: calcOutValue(12);
        line-height: 120%;
        text-align: left;

        svg {
            width: calcOutValue(30);
            height: calcOutValue(30);
        }
    }
}

@media(max-width: 767px) {
    .notification {
        top: calcOutValue(124);
        right: calcOutValue(20);
        width: calcOutValue(248);
    }

    .notitem {
        padding: calcOutValue(14) calcOutValue(30);

        &_cont {
            font-size: calcOutValue(10);
            font-weight: 400;
            line-height: 120%;
        }
    }
}