.checked-terms {
    display: flex;
    align-items: center;
    gap: calcOutValue(11);

    &__text {
        font-size: calcOutValue(10);
        font-weight: 300;

        a {
            color: var(--color-w-blue);

            &:hover {
                text-decoration: none;
            }
        }
    }
}

.check {
    cursor: pointer;

    span {
        display: block;
        width: calcOutValue(18);
        border-radius: calcOutValue(2);
        height: calcOutValue(18);
        border: calcOutValue(1) solid var(--color-ui-Blue-button-disable);
    }

    input {
        display: none;

        &:checked+span {
            background: url(../../icons/check_small.svg) center/156% 156% no-repeat, var(--color-ui-dark-Blue);
            border-color: var(--color-ui-dark-Blue);
        }
    }
}

@media(max-width: 767px) {
    .checked-terms {
        gap: calcOutValue(15);
        max-width: 90%;
        margin: 0 auto;
        padding: calcOutValue(6) 0;

        &__text {
            color: var(--color-ui-dark-Blue);
            font-weight: 400;
            a {
                text-decoration: none;
            }
        }
    }
}