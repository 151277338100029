.tcontent {
    background: var(--color-white);
    padding: calcOutValue(84) calcOutValue(95) calcOutValue(84) calcOutValue(80);

    &__head {
        display: flex;
        align-items: center;
        gap: calcOutValue(10);
    }

    &__btn_back {
        width: calcOutValue(40);
        height: calcOutValue(40);

        svg {
            width: inherit;
            height: inherit;
        }
    }

    &__title {
        font-size: calcOutValue(36);
        font-weight: 500;
        color: var(--color-ui-dark-Blue);
    }

    &-text {
        color: var(--color-ui-dark-Blue);
        font-size: calcOutValue(16);
        line-height: calcOutValue(22);
        letter-spacing: calcOutValue(-0.32);
        margin-top: calcOutValue(36);

        p {
            margin: calcOutValue(24) 0;
        }

        ul {
            list-style: disc;
            padding-left: calcOutValue(24);
            li {
                &:not(:first-child) {
                    margin-top: calcOutValue(10);
                }
            }
        }
    }
}

@media(max-width: 767px) {
    .tcontent {
        padding: calcOutValue(28) 0 calcOutValue(28) 0;
        background: transparent;

        &__btn_back {
            display: none;
        }
    }

    .tcontent-text {
        font-size: calcOutValue(14);
        font-weight: 300;
        color: var(--color-ui-dark-Blue);
        margin-top: calcOutValue(1);
    }

    .tcontent__title {
        font-size: calcOutValue(24);
        text-transform: uppercase;
    }
}